import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { take } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable()

export class MessagingService {
  private tkns = [];
  private rtnCount = 0;
  constructor(private afMessaging: AngularFireMessaging,
    private db: AngularFirestore,
    private afAuth: AngularFireAuth,
    private noti: NotificationService) { }

  requestPermission() {
    this.afMessaging.requestToken
      .subscribe(
        (token) => {
          //   console.log('Permission granted!', token);
          //  this.noti.showSuccess(token, 'Permission granted! Save to the server!');
          this.updateToken(token);
        },
        (error) => {
          this.noti.showError(error, 'Permission granted! Error..');
          console.error(error);
        },
      );
  }

  updateToken(token) {
    this.afAuth.authState.pipe(take(1)).subscribe(async user => {
      if (!user) {
        return;
      }

      const data = { [user.uid]: token };

      this.checkTotenExist(token).then(async (r) => {
        if (!r) {
          await this.db.collection('admin').doc(user.uid).collection('fcmTokens').doc().set({ 'token': token }).then(async (rec) => {
            await this.db.collection('dev-tokens').doc().set({ 'token': token }).then((rec1) => {
              this.noti.showSuccess(this.tkns, 'Token Added');
            });
          });
        }
      })
    })
  }

  async checkTotenExist(token): Promise<boolean> {
    let rtn = false;
    await this.db.collectionGroup('fcmTokens', ref => ref.where('token', '==', token)).get().toPromise().then((snap) => {
      this.rtnCount = snap.size;

    })

    if (this.rtnCount > 0) {
      this.noti.showInfo('', 'Permission already granted!');
      console.log(token);
      rtn = true;
    }
    return rtn;
  }

  sendMsg() {
    this.tkns = [];
    this.db.collection('admin').get().forEach((doc) => {
      doc.docs.forEach((dt) => {
        dt.ref.collection('fcmTokens').get().then((snap) => {
          snap.forEach((doc2) => {
            this.tkns.push(doc2.data().token);
          })
         
        }) 
       
      })
    });
  }

}