import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Leads } from '../models/leads.model';
import { AppUtilServiceService } from '../services/app-util.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-sell-your-home',
  templateUrl: './sell-your-home.component.html',
  styleUrls: ['./sell-your-home.component.css']
})
export class SellYourHomeComponent implements OnInit {
  public sellForm: FormGroup;
  public msg: string;
  public msgError = '';
  submitted = false;
  public fm: Leads;

  public GetPropertyTypes = [{ "propType": "SFH", "propTypeDesc": "Single Family Home", "activeFlag": "Y", "sortOrder": 1 }, { "propType": "TH", "propTypeDesc": "Townhome", "activeFlag": "Y", "sortOrder": 2 }, { "propType": "COND", "propTypeDesc": "Condominium", "activeFlag": "Y", "sortOrder": 3 }, { "propType": "MFH", "propTypeDesc": "Multi Family 1 to 4 units", "activeFlag": "Y", "sortOrder": 4 }, { "propType": "MFM4", "propTypeDesc": "Multi Family 5+ units", "activeFlag": "Y", "sortOrder": 5 }, { "propType": "MOBH", "propTypeDesc": "Mobile Home in Lot", "activeFlag": "Y", "sortOrder": 10 }, { "propType": "MOBL", "propTypeDesc": "Mobile Home with Land", "activeFlag": "Y", "sortOrder": 12 }, { "propType": "COMM", "propTypeDesc": "Commercial", "activeFlag": "Y", "sortOrder": 16 }, { "propType": "LAND", "propTypeDesc": "Land", "activeFlag": "Y", "sortOrder": 18 }];
  public GetSellingReasons = [{ "reason_id": 8, "reason_desc": "Divorce", "sortOrder": 0 }, { "reason_id": 7, "reason_desc": "Downsizing", "sortOrder": 0 }, { "reason_id": 1, "reason_desc": "Financial Hardship", "sortOrder": 0 }, { "reason_id": 5, "reason_desc": "Inherited", "sortOrder": 0 }, { "reason_id": 11, "reason_desc": "Make Me Move", "sortOrder": 0 }, { "reason_id": 12, "reason_desc": "Not ready to sell, just curious on value", "sortOrder": 0 }, { "reason_id": 13, "reason_desc": "Others", "sortOrder": 999 }, { "reason_id": 6, "reason_desc": "Relocating", "sortOrder": 0 }, { "reason_id": 9, "reason_desc": "Repairs/Damage", "sortOrder": 0 }, { "reason_id": 10, "reason_desc": "Tired Landlord", "sortOrder": 0 }];
  public GetHomeSellTime = [{ "sellDays": "ASAP", "daysDesc": "ASAP", "activeFlag": "Y", "sortOrder": 1 }, { "sellDays": "30D", "daysDesc": "30 Days", "activeFlag": "Y", "sortOrder": 2 }, { "sellDays": "60D", "daysDesc": "30 to 60 Days", "activeFlag": "Y", "sortOrder": 3 }, { "sellDays": "90D", "daysDesc": "60 to 90 Days", "activeFlag": "Y", "sortOrder": 4 }, { "sellDays": "NORSH", "daysDesc": "I'm in no rush", "activeFlag": "Y", "sortOrder": 5 }];


  formattedaddress = "";
  options = {
    componentRestrictions: {
      country: ["US"]
    }
  }

  public AddressChange(address: any) {
    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address;
  }

  constructor(private appUtil: AppUtilServiceService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private noti: NotificationService) {

  }

  ngOnInit(): void {
    this.sellForm = new FormGroup({

      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
      message: new FormControl(''),
      propertyType: new FormControl('', Validators.required),
      sellingReason: new FormControl('', Validators.required),
      sellingTimeFrame: new FormControl('', Validators.required),
      listedWithAgent: new FormControl('', Validators.required),

    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.sellForm.controls;
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.sellForm.invalid) {
      return;
    }

    this.fm = this.sellForm.value;
    this.fm.createAt = this.appUtil.getServerTimestamp();
    this.fm.statusFlag = 'NEW';
    this.fm.propertyaddress = this.formattedaddress;

    return await this.afs.collection("leads").doc()
      .set(this.fm)
      .then((res) => {
        this.noti.showSuccess('We will get back within 24 hours.', 'Thank you');
        this.submitted = false;
        this.sellForm.reset();
      })
      .catch((error) => {
        this.noti.showError(error, 'Error writing')
        //   this.msgError = error;
      });

  }

  onReset() {
    this.submitted = false;
    this.sellForm.reset();
  }

}
