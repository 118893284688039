import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AppUtilServiceService } from 'src/app/services/app-util.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
 
})
export class HeaderComponent implements OnInit {
  public currentUser;
  constructor(public appUtil: AppUtilServiceService, public auth: AuthService, private afAuth: AngularFireAuth,) { }

  async ngOnInit(): Promise<void> {
    this.currentUser = await this.afAuth.currentUser;
  }

  public async signOut() {
    await this.auth.signOut();
  }


  public isAuthenticated =
    this.auth.isAuthenticated();




}
