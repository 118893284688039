import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { MessagingService } from '../services/messaging.service';

import { NotificationService } from '../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';


@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  public uid;
  displayedColumns: string[] = [ 'name', 'email', 'phone', 'address', 'createAt', 'statusFlag'];
  dataDB = [];
  dataSource = new MatTableDataSource(this.dataDB);

  @ViewChild(MatPaginator) paginator: MatPaginator;

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }


  constructor(public afAuth: AngularFireAuth,
    public _noti: NotificationService,
    private msg: MessagingService,
    private router: Router,
    private authService: AuthService,
    private afs: AngularFirestore,) {

    this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {
        this.uid = res.uid;
      } else {
        this.router.navigate(['login']);
      }
    });


    this.afs.collection('leads').get().toPromise().then((snap) => {
      snap.forEach((doc) => {
        if (doc.exists) {
          // console.log(doc.id, " => ", doc.data());
          const data = {
            id: doc.id,
            address: doc.data()['address'],
            createAt: doc.data()['createAt'],
            email: doc.data()['email'],
            name: doc.data()['name'],
            phone: doc.data()['phone'],
            propertyaddress: doc.data()['propertyaddress'],
            statusFlag: doc.data()['statusFlag'],
          }
          this.dataDB.push(data);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
        }
      })
     // console.log(this.dataDB);
      this.dataSource = new MatTableDataSource(this.dataDB);
    })
  }
  requestPermission() {
    this.msg.requestPermission();
  }

  sendMsg() {
    this.msg.sendMsg();
  }


  logOut() {
    this.authService.logout();
  }
  /* 
    sendAwsSMS() {
  
      AWS.config.region = 'us-east-1';
      var sns = new AWS.SNS();
  
      var params = {
        Message: 'this is a test message KM',
        MessageStructure: 'string',
        PhoneNumber: '+17036267861'
      };
  
      sns.publish(params, function (err, data) {
        if (err) console.log(err, err.stack); // an error occurred
        else console.log(data);           // successful response
      });
    } */
  ngOnInit(): void { }

}
