<div class="container">
  <br>
  <div class="formGroup">
    <input type="email" class="formControl" placeholder="Username" #userEmail required>
  </div>
  <br>
  <div class="formGroup">
    <input type="password" class="formControl" placeholder="Password" #userPassword required>
  </div>
  <br>
  <div class="formGroup">
    <input type="button" class="btn btn-primary" value="Sign in" (click)="logIn(userEmail.value, userPassword.value)">
  </div>
</div>`