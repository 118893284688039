import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { mergeMapTo } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessagingService } from '../services/messaging.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  form: FormGroup;


  loading = false;

  serverMessage: string;

  constructor(private afAuth: AngularFireAuth,
    private fb: FormBuilder,
    private router: Router,
    private afMessaging: AngularFireMessaging,
    private msg: MessagingService,
    private authService: AuthService) { }

  public requestPermission() {
    this.afMessaging.requestPermission
      .pipe(mergeMapTo(this.afMessaging.tokenChanges))
      .subscribe(
        (token) => { console.log('Permission granted! Save to the server!', token); },
        (error) => { console.error(error); },
      );
  }


  async ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: [
        '',
        [Validators.minLength(6), Validators.required]
      ]
    });
  }


  // Sign in with email/password
  public logIn(email, password) {
    return this.authService.login(email, password)
      .then((result) => {

        this.router.navigate(['admin']);
      }).catch((error) => {
        window.alert(error.message)
      })
  }

  // Sign in with email/password
  public signIn(email, password) {
    return this.afAuth.signInWithEmailAndPassword(email, password)
      .then((result) => {
        console.log(result.user.uid);
        this.router.navigate(['admin']);
      }).catch((error) => {
        window.alert(error.message)
      })
  }
}