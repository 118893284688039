import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Leads } from 'src/app/models/leads.model';
import { AppUtilServiceService } from 'src/app/services/app-util.service';
import { NotificationService } from 'src/app/services/notification.service';




@Component({
  selector: 'app-offer-form',
  templateUrl: './offer-form.component.html',
  styleUrls: ['./offer-form.component.css']
})
export class OfferFormComponent implements OnInit {
  public quickForm: FormGroup;
  public msg: string;
  public msgError = '';
  submitted = false;
  public fm: Leads;

 

  formattedaddress = "";
  options = {
    componentRestrictions: {
      country: ["US"]
    }
  }



  constructor(public appUtil: AppUtilServiceService,
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private noti: NotificationService) {
 
  }

  ngOnInit(): void {


    this.quickForm = new FormGroup({

      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      address: new FormControl('', Validators.required),
    });

  }



  // convenience getter for easy access to form fields
  get f() { return this.quickForm.controls; }

  public AddressChange(address: any) {
    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address;

 

/*     this.adr.address = address.address_components[0]['long_name'] + ' ' + address.address_components[1]['long_name'];
    this.adr.city = address.address_components[2]['long_name'];
    this.adr.state = address.address_components[5]['short_name'];
    this.adr.zipcode = address.address_components[6]['long_name'];
    console.log('adr.address: ' + this.adr.address);
    console.log('adr.city: ' + this.adr.city);
    console.log('adr.state: ' + this.adr.state);
    console.log('adr.zipcode: ' + this.adr.zipcode); */
  }

  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.quickForm.invalid) {
      return;
    }

    console.log('this.quickForm: ' + this.quickForm.invalid);

    this.fm = this.quickForm.value;
    this.fm.createAt = this.appUtil.getServerTimestamp();
    this.fm.statusFlag = 'NEW';
    this.fm.propertyaddress = this.formattedaddress;
 
    return await this.afs.collection("leads").doc()
      .set(this.fm)
      .then((res) => {
        this.noti.showSuccess('We will get back within 24 hours.', 'Thank you');
        this.submitted = false;
        this.quickForm.reset();
      })
      .catch((error) => {
        this.noti.showError(error, 'Error writing')
        this.submitted = false;
        this.msgError = error;
      });

  }
}
