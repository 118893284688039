import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { map } from 'rxjs/operators';
import { AppUtilServiceService } from 'src/app/services/app-util.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],

})
export class FooterComponent implements OnInit {
  public copyRightYr: number = Date.now();
  public isLoggedIn=false;
  constructor(public appUtil: AppUtilServiceService,public afAuth: AngularFireAuth,
    private auth: AuthService) {

      this.afAuth.authState.subscribe(res => {
        if (res && res.uid) {    
          this.isLoggedIn = true;
        }  
      });

  }

  ngOnInit(): void {
    
  }

  public async logout() {
    this.isLoggedIn = false;
      this.auth.signOut();
 

  }



}
