<div class="container  bg-color-light-scale-border-top-4">


    <div class="row py-4">
        <div class="col-lg-6">

            <div class="alert alert-success" role="alert" *ngIf="rtn">
                {{rtn }}
            </div>

            <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                <div class="form-row">
                    <div class="form-group col-12">
                        <label class="form-label font-weight-bold text-dark">Full Name</label>

                        <input type="text" formControlName="name" class="form-control" minlength="4" maxlength="100"
                            [ngClass]="{ 'is-invalid': submitted && f.name.errors }" required>

                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="errortext">
                            <div *ngIf="f.name.errors.required">
                                Name is required.
                            </div>
                            <div *ngIf="f.name.errors.minlength">
                                Name must be atleast 4 characters long, we need another
                                {{f.name.errors.minlength.requiredLength - f.name.errors.minlength.actualLength}}
                                characters.
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col-lg-6">
                        <label class="required font-weight-bold text-dark text-2">Phone</label>
                        <input type="number" placeholder="(123) 456-7890" class="form-control" formControlName="phone"
                            minlength="10" max="10" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">

                        <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="errortext">
                            <div *ngIf="f.phone.errors.required">
                                Phone number is required.
                            </div>
                            <div *ngIf="f.phone.errors.minlength">
                                Phone must be 10 digit long, we need another
                                {{f.phone.errors.minlength.requiredLength - f.phone.errors.minlength.actualLength}}
                                digits.
                            </div>
                        </div>
                    </div>



                    <div class="form-group col-lg-6">
                        <label class="required font-weight-bold text-dark text-2">Email Address</label>
                        <input formControlName="email" type="text" maxlength="100" class="form-control"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

                        <div *ngIf="submitted && f.email.errors" class="errortext">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col">
                        <label class="font-weight-bold text-dark text-2">Subject*</label>
                        <input type="text" maxlength="100" class="form-control" formControlName="subject"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

                        <div *ngIf="f.subject.invalid && (f.subject.dirty || f.subject.touched)" class="errortext">
                            <div *ngIf="f.subject.errors.required">Subject is required</div>

                            <div *ngIf="f.subject.errors.maxlength">

                                {{f.subject.errors.minlength.requiredLength - f.subject.errors.minlength.actualLength}}
                                digits.
                            </div>
                        </div>


                    </div>
                </div>

                <div class="form-row">
                    <div class="form-group col">
                        <label class="font-weight-bold text-dark text-2">Property Address</label>
                        <input ngx-google-places-autocomplete [options]='options' formControlName="address"
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }" placeholder="Enter you property address..."
                            (onAddressChange)="AddressChange($event)" maxlength="200" class="form-control" required />
                    </div>
                </div>



                <div class="form-row">
                    <div class="form-group col">
                        <label class="required font-weight-bold text-dark text-2">Message</label>
                        <textarea minlength="10" maxlength="500" rows="4" class="form-control" formControlName="message"
                            [ngClass]="{ 'is-invalid': submitted && f.message.errors }">
                        </textarea>
                        <div *ngIf="f.message.invalid && (f.message.dirty || f.message.touched)" class="errortext">
                            <div *ngIf="f.message.errors.required">Message is required, atleast 10 characters required
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col">
                        <button class="btn btn-dark bg-color-hover-primary px-4 py-md-2" type="submit" value="Submit"
                            ng-disabled="loading">
                            <span ng-show="loading" class="spinner-grow spinner-grow-sm" role="status"
                                aria-hidden="true"></span>
                            Submit
                        </button>
                    </div>
                </div>

            </form>

            <div class="alert alert-danger" role="alert" *ngIf="rtnError">
                {{rtnError }}
            </div>
        </div>

        <div class="col-lg-6">

            <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="800">
                <h4 class="mt-2 mb-1">Our <strong>Office</strong></h4>
                <ul class="list list-icons list-icons-style-2 mt-2">
                    <li><em class="fas fa-map-marker-alt top-6"></em> <strong class="text-dark">Address:</strong>
                        {{appUtil.Company.FullAddress}}</li>
                    <li><em class="fas fa-phone top-6"></em> <strong
                            class="text-dark">Phone:</strong>{{appUtil.Company.Phone}}</li>
                    <li><em class="fas fa-envelope top-6"></em> <strong class="text-dark">Email:</strong> <a
                            href='mailto:{{appUtil.Company.Email}}'>{{appUtil.Company.Email}}</a></li>
                </ul>
            </div>

            <div class="appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="950">
                <h4 class="pt-5">Business <strong>Hours</strong></h4>
                <ul class="list list-icons list-dark mt-2">
                    <li><em class="far fa-clock top-6"></em> Monday - Friday - 9am to 5pm</li>
                    <li><em class="far fa-clock top-6"></em> Saturday - 9am to 2pm</li>
                    <li><em class="far fa-clock top-6"></em> Sunday - Closed</li>
                </ul>
            </div>

            <h4 class="pt-5">Get in <strong>Touch</strong></h4>
            <p class="lead mb-0 text-4">
                Home Buyers Biz is the trusted solution in Northern Virginia to avoid the stress, frustration, and
                uncertainty that can come with selling a home the traditional way.
                When you work with Home Sale Solutions, you won't need to bother with repairs, cleaning or showings.
                There are also no commissions paid to realtors and we pay all of the closing costs. It is very
                simple.
            </p>
            <p>
                Our offer will not change before closing, and you won't be hit by any surprise fees. We'll be there
                with you to walk you through each step of the simple sale process.
            </p>
        </div>
    </div>
</div>