<div class="container py-4">

    <div class="row">
        <div class="col">

            <h2 class="font-weight-normal text-7 mb-2"> <strong
                    class="font-weight-extra-bold">Privacy</strong></h2>
            <p class="lead">
                Home Buyers Biz (“us”, “we”, or “our”) operates this website (the “Service”).
            </p>
            <p class="lead">
                This page informs you of our policies regarding the collection, use, and disclosure of personal data
                when you use our Service and the choices you have associated with that data.
            </p>
            <p class="lead">
                We use your data to provide and improve the Service. By using the Service, you agree to the collection
                and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy,
                terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from
                this site
            </p>
            <p><strong>What information do we collect?</strong></p>
            <p>We collect information from you when you subscribe to our newsletter, fill out a form or submit property
                information.</p>
            <p>When ordering or registering on our site, as appropriate, you may be asked to enter your: name, e-mail
                address, mailing address or phone number. You may, however, visit our site anonymously.</p>
            <p><strong>What do we use your information for?</strong></p>
            <p>Any of the information we collect from you may be used in one of the following ways:</p>
            <ul>
                <li>To process transactions</li>
            </ul>
            <blockquote>
                <p>Your information, whether public or private, will not be sold, exchanged, transferred, or given to
                    any other company for any reason whatsoever, without your consent, other than for the express
                    purpose of delivering the purchased product or service requested.</p>
            </blockquote>
            <ul>
                <li>To send periodic emails</li>
            </ul>
            <p>The email address you provide may be used to send you information, respond to inquiries, and/or other
                requests or questions.</p>
            <p><strong>How do we protect your information?</strong></p>
            <p>We implement a variety of security measures to maintain the safety of your personal information when you
                enter, submit, or access your personal information.</p>
            <p><strong>Do we disclose any information to outside parties?</strong></p>
            <p>We do not sell, trade, or otherwise transfer to outside parties your personally identifiable information.
                This does not include trusted third parties who assist us in operating our website, conducting our
                business, or servicing you, so long as those parties agree to keep this information confidential. We may
                also release your information when we believe release is appropriate to comply with the law, enforce our
                site policies, or protect ours or others rights, property, or safety. However, non-personally
                identifiable visitor information may be provided to other parties for marketing, advertising, or other
                uses.</p>
            <p><strong>Third party links</strong></p>
            <p>Occasionally, at our discretion, we may include or offer third party products or services on our website.
                These third party sites have separate and independent privacy policies. We therefore have no
                responsibility or liability for the content and activities of these linked sites. Nonetheless, we seek
                to protect the integrity of our site and welcome any feedback about these sites.</p>
            <p><strong>California Online Privacy Protection Act Compliance</strong></p>
            <p>Because we value your privacy we have taken the necessary precautions to be in compliance with the
                California Online Privacy Protection Act. We therefore will not distribute your personal information to
                outside parties without your consent.</p>
            <p><strong>Childrens Online Privacy Protection Act Compliance</strong></p>
            <p>We are in compliance with the requirements of COPPA (Childrens Online Privacy Protection Act), we do not
                collect any information from anyone under 13 years of age. Our website, products and services are all
                directed to people who are at least 13 years old or older.</p>
            <p><strong>Personal, Financial, And Property Information</strong></p>
            <p>Our company is a real estate services firm that may require you submitting certain information so we can
                help you in your situation. This information may include your contact info, information about your
                property, or even in some cases financial information needed to help us analyze your property and
                situation. This information is for our use only and will not be shared with outside parties unless as
                required to help us help you solve your real estate situation.</p>
            <p><strong>Online Privacy Policy Only</strong></p>
            <p>This online privacy policy applies only to information collected through our website and not to
                information collected offline.</p>
            <p><strong>Changes to our Privacy Policy</strong></p>
            <p>If we decide to change our privacy policy, we will post those changes on this page.</p>
            <p><strong>Contact Us</strong></p>
            <p>If there are any questions regarding this privacy policy you may contact us with the information on our
                <a routerLink="/home">contact page</a>.</p>
   
                {{appUtil.Company.Name}}  
            <address>
                {{appUtil.Company.Address1}}<br />
                {{appUtil.Company.Address2}}
            </address>
      
        </div>
    </div>
</div>