<header id="header" class="header-effect-shrink"
    data-plugin-options="{'stickyEnabled': true, 'stickyEffect': 'shrink', 'stickyEnableOnBoxed': true, 'stickyEnableOnMobile': true, 'stickyChangeLogo': true, 'stickyStartAt': 120, 'stickyHeaderContainerHeight': 70}">
    <div class="header-body border-top-0">
        <div class="header-top">
            <div class="container">
                <div class="header-row py-2">
                    <div class="header-column justify-content-start">
                        <div class="header-row">
                            <nav class="header-nav-top" aria-label="nav">
                                <ul class="nav nav-pills">
                                    <li
                                        class="nav-item nav-item-left-border nav-item-left-border-remove nav-item-left-border-sm-show">
                                        <a class="ws-nowrap" href='tel:{{ appUtil.Company.Phone }}'>
                                            <strong><em class="fas fa-phone"></em>{{appUtil.Company.Phone}}</strong>
                                        </a>
                                    </li>

                                    <li class="nav-item nav-item-anim-icon d-none d-md-block" ng-if="1==2">
                                        <a class="nav-link pl-0" routerLink="/about-us" hidden><em
                                                class="fas fa-angle-right"></em> About
                                            Us</a>
                                    </li>
                                    <li class="nav-item nav-item-anim-icon d-none d-md-block">
                                        <a class="nav-link" routerLink="/contact-us"><em
                                                class="fas fa-angle-right"></em>
                                            Contact Us</a>
                                    </li>
                                    <li ng-if="1==2"
                                        class="nav-item dropdown nav-item-left-border d-none d-sm-block nav-item-left-border-remove nav-item-left-border-md-show">
                                        <a class="nav-link" href="#" role="button" id="dropdownLanguage"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <img src="assets/img/blank.gif" class="flag flag-us" alt="English" />
                                            English
                                            <em class="fas fa-angle-down"></em>
                                        </a>
                                        <div class="dropdown-menu" aria-labelledby="dropdownLanguage">
                                            <a class="dropdown-item" href="#">
                                                <img src="assets/img/blank.gif" class="flag flag-us" alt="English" />
                                                English
                                            </a>
                                            <a class="dropdown-item" href="#">
                                                <img src="assets/img/blank.gif" class="flag flag-es" alt="English" />
                                                Español
                                            </a>
                                            <a class="dropdown-item" href="#">
                                                <img src="assets/img/blank.gif" class="flag flag-fr" alt="English" />
                                                Française
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div class="header-column justify-content-end">
                        <div class="header-row">
                            <ul class="header-social-icons social-icons d-none d-sm-block social-icons-clean">
                                <li class="social-icons-facebook"><a href="http://www.facebook.com/" target="_blank"
                                        title="Facebook"><em class="fab fa-facebook-f"></em></a></li>
                                <li class="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank"
                                        title="Twitter"><em class="fab fa-twitter"></em></a></li>
                                <li class="social-icons-linkedin"><a href="http://www.linkedin.com/" target="_blank"
                                        title="Linkedin"><em class="fab fa-linkedin-in"></em></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="header-container container">
            <div class="header-row">
                <div class="header-column">
                    <div class="header-row">
                        <div class="header-logo navbar-left">
                            <a href="/">
                                <img alt="Home Buyers Biz" width="180" height="81" data-sticky-width="180"
                                    data-sticky-height="70" class="float-left" src="{{appUtil.CompanyLogo()}}">
                            </a>
                        </div>
                    </div>
                </div>
                <div class="header-column justify-content-end">
                    <div class="header-row">
                        <div
                            class="header-nav header-nav-line header-nav-top-line header-nav-top-line-with-border order-2 order-lg-1">
                            <div
                                class="header-nav-main header-nav-main-square header-nav-main-effect-2 header-nav-main-sub-effect-1">
                                <nav class="collapse">
                                    <ul class="nav nav-pills" id="mainNav">
                                        <li>
                                            <a class="dropdown-item" routerLink="/sell-your-home">
                                                Get a Cash Offer
                                            </a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" routerLink="faq">
                                                FAQ
                                            </a>
                                        </li>

                                        <li class="nav-item d-block d-md-none">
                                            <a class="nav-link" routerLink="/contact-us">
                                                Contact us
                                            </a>
                                        </li>
                                      
                                    </ul>
                                 
                                </nav>
                            </div>
                            <button class="btn header-btn-collapse-nav" data-toggle="collapse"
                                data-target=".header-nav-main nav">
                                <em class="fas fa-bars"></em>
                            </button>
                        </div>
                        <div class="header-nav-features header-nav-features-no-border header-nav-features-lg-show-border order-1 order-lg-2"
                            hidden>
                            <div class="header-nav-feature header-nav-features-search d-inline-flex">
                                <a href="#" class="header-nav-features-toggle" data-focus="headerSearch"><em
                                        class="fas fa-search header-nav-top-icon"></em></a>
                                <div class="header-nav-features-dropdown" id="headerTopSearchDropdown">
                                    <form role="search" action="page-search-results.html" method="get">
                                        <div class="simple-search input-group">
                                            <input class="form-control text-1" id="headerSearch" name="q" type="search"
                                                value="" placeholder="Search...">
                                            <span class="input-group-append">
                                                <button class="btn" type="submit">
                                                    <em class="fa fa-search header-nav-top-icon"></em>
                                                </button>
                                            </span>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>