<div class="container py-4">
    <h1>AboutUs</h1>
    <div class="row">
        <div class="col">
            <p>
                We’re a local house buying company known for fair offers. We understand the attachment to a home whether
                it’s your own home, a friend’s, or a relative’s. That’s why we spend as much time as you need with every
                offer we make. We strive to provide not just a fair offer price, but a holistic solution.
            </p>
            <p>
                We are cash home buyers in the DMV. We actually go to the houses before making our as-is offer. We also
                have low overhead costs due to our small team, so the offers we make are usually higher, more accurate,
                and backed by data that we will show you.
            </p>
            <p>
                Curious how our process differs from listing a house on the market, or why we are different than other
                cash offer companies? Find out how our home buying process works.
            </p>

        </div>
    </div>
</div>