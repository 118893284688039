import { Component, OnInit } from '@angular/core';
import { AppUtilServiceService } from '../services/app-util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  public GetServingLocations = [];

  constructor(private appUtil: AppUtilServiceService) {
    this.GetServingLocations = appUtil.GetServingLocations
  }

  ngOnInit(): void { }
}
