import { Injectable } from '@angular/core';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AppUtilServiceService {
  AppVersion = "4.1.8";

  Company = {
    "Name": "Elite Homes Inc.",
    "WebSiteName": "Home Buyers Biz",
    "Email": "info@homebuyersbiz.com",
    "Phone": "(804) 256-8100",
    "SMS": "8042568100",
    "FullAddress": "145 Fleet Street, STE 142, National Harbor, MD 20745",
    "Address1": "145 Fleet Street, STE 142",
    "Address2": "National Harbor, MD 20745",
  }
  stData = [
    { "state": "Alabama", "st": "AL" }, { "state": "Alaska", "st": "AK" }, { "state": "American Samoa", "st": "AS" },
    { "state": "Arizona", "st": "AZ" }, { "state": "Arkansas", "st": "AR" }, { "state": "California", "st": "CA" },
    { "state": "Colorado", "st": "CO" }, { "state": "Connecticut", "st": "CT" }, { "state": "Delaware", "st": "DE" },
    { "state": "District Of Columbia", "st": "DC" }, { "state": "Federated States Of Micronesia", "st": "FM" }, { "state": "Florida", "st": "FL" },
    { "state": "Georgia", "st": "GA" }, { "state": "Guam", "st": "GU" }, { "state": "Hawaii", "st": "HI" },
    { "state": "Idaho", "st": "ID" }, { "state": "Illinois", "st": "IL" }, { "state": "Indiana", "st": "IN" },
    { "state": "Iowa", "st": "IA" }, { "state": "Kansas", "st": "KS" }, { "state": "Kentucky", "st": "KY" },
    { "state": "Louisiana", "st": "LA" }, { "state": "Maine", "st": "ME" }, { "state": "Marshall Islands", "st": "MH" },
    { "state": "Maryland", "st": "MD" }, { "state": "Massachusetts", "st": "MA" }, { "state": "Michigan", "st": "MI" },
    { "state": "Minnesota", "st": "MN" }, { "state": "Mississippi", "st": "MS" }, { "state": "Missouri", "st": "MO" },
    { "state": "Montana", "st": "MT" }, { "state": "Nebraska", "st": "NE" }, { "state": "Nevada", "st": "NV" },
    { "state": "New Hampshire", "st": "NH" }, { "state": "New Jersey", "st": "NJ" }, { "state": "New Mexico", "st": "NM" },
    { "state": "New York", "st": "NY" }, { "state": "North Carolina", "st": "NC" }, { "state": "North Dakota", "st": "ND" },
    { "state": "Northern Mariana Islands", "st": "MP" }, { "state": "Ohio", "st": "OH" }, { "state": "Oklahoma", "st": "OK" },
    { "state": "Oregon", "st": "OR" }, { "state": "Palau", "st": "PW" }, { "state": "Pennsylvania", "st": "PA" },
    { "state": "Puerto Rico", "st": "PR" }, { "state": "Rhode Island", "st": "RI" }, { "state": "South Carolina", "st": "SC" },
    { "state": "South Dakota", "st": "SD" }, { "state": "Tennessee", "st": "TN" }, { "state": "Texas", "st": "TX" },
    { "state": "Utah", "st": "UT" }, { "state": "Vermont", "st": "VT" }, { "state": "Virgin Islands", "st": "VI" },
    { "state": "Virginia", "st": "VA" }, { "state": "Washington", "st": "WA" },
    { "state": "West Virginia", "st": "WV" }, { "state": "Wisconsin", "st": "WI" }, { "state": "Wyoming", "st": "WY" }
  ];

  GetServingLocations = [{ "seqId": 1, "city": "Adelphi", "state": "MD", "activeFlag": "Y" },
  { "seqId": 2, "city": "Alexandria", "state": "VA", "activeFlag": "Y" }, { "seqId": 3, "city": "Annandale", "state": "VA", "activeFlag": "Y" },
  { "seqId": 4, "city": "Arlington", "state": "VA", "activeFlag": "Y" }, { "seqId": 5, "city": "Bethesda", "state": "MD", "activeFlag": "Y" },
  { "seqId": 6, "city": "Bladensburg", "state": "MD", "activeFlag": "Y" }, { "seqId": 7, "city": "Bowie", "state": "MD", "activeFlag": "Y" },
  { "seqId": 8, "city": "Brandywine", "state": "MD", "activeFlag": "Y" }, { "seqId": 9, "city": "Burke", "state": "VA", "activeFlag": "Y" },
  { "seqId": 10, "city": "Capitol Heights", "state": "MD", "activeFlag": "Y" },
  { "seqId": 11, "city": "Centreville", "state": "VA", "activeFlag": "Y" }, { "seqId": 12, "city": "Cheverly", "state": "MD", "activeFlag": "Y" },
  { "seqId": 13, "city": "Chevy Chase", "state": "MD", "activeFlag": "Y" }, { "seqId": 14, "city": "Chillum", "state": "MD", "activeFlag": "Y" },
  { "seqId": 15, "city": "Clinton", "state": "MD", "activeFlag": "Y" }, { "seqId": 16, "city": "College Park", "state": "MD", "activeFlag": "Y" },
  { "seqId": 17, "city": "Dale City", "state": "VA", "activeFlag": "Y" }, { "seqId": 18, "city": "District Heights", "state": "MD", "activeFlag": "Y" },
  { "seqId": 19, "city": "Fairfax", "state": "VA", "activeFlag": "Y" }, { "seqId": 20, "city": "Falls Church", "state": "VA", "activeFlag": "Y" },
  { "seqId": 21, "city": "Falls Church City", "state": "VA", "activeFlag": "Y" },
  { "seqId": 22, "city": "Fort Washington", "state": "MD", "activeFlag": "Y" }, { "seqId": 23, "city": "Great Falls", "state": "VA", "activeFlag": "Y" },
  { "seqId": 24, "city": "Greenbelt", "state": "MD", "activeFlag": "Y" }, { "seqId": 25, "city": "Herndon", "state": "VA", "activeFlag": "Y" },
  { "seqId": 26, "city": "Hyattsville", "state": "MD", "activeFlag": "Y" }, { "seqId": 27, "city": "Kensington", "state": "MD", "activeFlag": "Y" },
  { "seqId": 28, "city": "Lake Ridge", "state": "VA", "activeFlag": "Y" }, { "seqId": 29, "city": "Lanham", "state": "MD", "activeFlag": "Y" },
  { "seqId": 30, "city": "Laurel", "state": "MD", "activeFlag": "Y" }, { "seqId": 31, "city": "Leesburg", "state": "VA", "activeFlag": "Y" },
  { "seqId": 32, "city": "Lorton", "state": "VA", "activeFlag": "Y" }, { "seqId": 33, "city": "Manassas", "state": "VA", "activeFlag": "Y" },
  { "seqId": 34, "city": "McLean", "state": "VA", "activeFlag": "Y" }, { "seqId": 35, "city": "Mitchellville", "state": "MD", "activeFlag": "Y" },
  { "seqId": 36, "city": "Mt Rainier", "state": "MD", "activeFlag": "Y" }, { "seqId": 37, "city": "New Carrollton", "state": "MD", "activeFlag": "Y" },
  { "seqId": 38, "city": "Olney", "state": "MD", "activeFlag": "Y" }, { "seqId": 39, "city": "Oxon Hill", "state": "MD", "activeFlag": "Y" },
  { "seqId": 40, "city": "Reston", "state": "VA", "activeFlag": "Y" }, { "seqId": 41, "city": "Rockville", "state": "MD", "activeFlag": "Y" },
  { "seqId": 42, "city": "Silver Spring", "state": "MD", "activeFlag": "Y" }, { "seqId": 43, "city": "Springfield", "state": "VA", "activeFlag": "Y" },
  { "seqId": 44, "city": "Takoma Park", "state": "MD", "activeFlag": "Y" }, { "seqId": 45, "city": "Temple Hills", "state": "MD", "activeFlag": "Y" },
  { "seqId": 46, "city": "Upper Marlboro", "state": "MD", "activeFlag": "Y" }, { "seqId": 47, "city": "Vienna", "state": "VA", "activeFlag": "Y" },
  { "seqId": 48, "city": "Waldorf", "state": "MD", "activeFlag": "Y" }, { "seqId": 49, "city": "Woodbridge", "state": "VA", "activeFlag": "Y" }];

  constructor() { }
  CompanyLogo() {
    return "assets/img/Site/HomeBuyersBiz2-Logo.png"
  }
  HomeBuyersBizLogo() {
    return "assets/img/Site/HomeBuyersBiz-Logo.png"
  }
  EliteHomesLogo() {
    return "assets/img/Site/EliteHome-logo.JPG"
  }
  CompanyWebSiteName() { return this.Company.WebSiteName; }
  WebsiteVersion() { return this.AppVersion; }
  getServerTimestamp() { return firebase.default.firestore.FieldValue.serverTimestamp(); }

}
