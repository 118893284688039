<form [formGroup]="quickForm" (ngSubmit)="onSubmit()">

    <div class="form-row">
        <div class="form-group col">
            <label for="propertyAddress" class="required text-color-light">Property Address</label>


            <input ngx-google-places-autocomplete [options]='options' formControlName="address"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" (onAddressChange)="AddressChange($event)"
                maxlength="200" class="form-control" placeholder="Enter you property address..." required />

            <div *ngIf="f.address.invalid && (f.address.dirty || f.address.touched)" class="errortext">
                <div *ngIf="f.address.errors.required">Property Address is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col">
            <label for="propertyAddress" class="required text-color-light">Full Name</label>
            <input type="text" maxlength="100" class="form-control" formControlName="name"
                placeholder="Enter you full name..." required autofocus>



            <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)" class="errortext">
                <div *ngIf="f.name.errors.required">Full Name is required</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col-lg-6">
            <label class="required text-color-light">Phone</label>
            <input type="number" placeholder="(123) 456-7890" class="form-control" formControlName="phone"
                minlength="10" max="10" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">

            <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)" class="errortext">
                <div *ngIf="f.phone.errors.required">
                    Phone number is required.
                </div>
                <div *ngIf="f.phone.errors.minlength">
                    Phone must be 10 digit long, we need another
                    {{f.phone.errors.minlength.requiredLength - f.phone.errors.minlength.actualLength}}
                    digits.
                </div>
            </div>
        </div>

        <div class="form-group col-lg-6">
            <label class="required text-color-light">Email Address</label>
            <input formControlName="email" type="text" maxlength="100" class="form-control"
                [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

            <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)" class="errortext">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Enter valid email address</div>
            </div>
        </div>
    </div>

    <div class="form-row">
        <div class="form-group col text-right">
            <button class="btn btn-dark bg-color-hover-primary px-4 py-md-2" type="submit">
                <span *ngIf="submitted" class="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                Submit
            </button>
        </div>
    </div>

    <div class="alert alert-success" role="alert" *ngIf="msg">
        <strong>Success!</strong> {{msg}}
    </div>

    <div class="alert alert-danger" role="alert" *ngIf="msgError">
        <strong>Error!</strong> {{msgError }}
    </div>
</form>