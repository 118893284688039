import { Component, ViewEncapsulation } from '@angular/core';
import { AppUtilServiceService } from './services/app-util.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.Emulated
})
export class AppComponent {
  public title = '';
  message;
  constructor(
    public appUtil: AppUtilServiceService,
    

  ) {
    this.title = appUtil.Company.WebSiteName
  }


  ngOnInit() {

   
    // this.afauth.user
    // .filter(user => !!user) // filter null
    // .take(1) // take first real user
    // .subscribe(user => {
    //   if (user) {
    //     this.msg.getPermission(user)
    //  //   this.msg.monitorRefresh(user)
    //     this.msg.receiveMessages()
    //   }
    // })
  }
}