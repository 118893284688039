import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import * as firebase from 'firebase';
import { first } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user$: firebase.default.User;

  constructor(public afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    public router: Router,
    private noti: NotificationService) {
    var ts = firebase.default.firestore.FieldValue.serverTimestamp();

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user$ = user;
    //    localStorage.setItem('user', JSON.stringify(this.user$));
      } else {
    //    localStorage.setItem('user', null);
      }
    })

  }

  async login(email: string, password: string) {
    var result = await this.afAuth.signInWithEmailAndPassword(email, password).then((res) => {
       this.router.navigate(['admin']);
       this.noti.showSuccess('Welcome '+ res.user.email,'Log-in');
    })
   
  }

  async logout() {
    await this.afAuth.signOut().then(() => {
      this.router.navigate(['']);
      this.noti.showInfo('Logout successfully.','Logging out');
    });
  //  localStorage.removeItem('user');
    
  }

 

 

  async signInAnonymously() {
    await this.afAuth.signInAnonymously()
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode + ': ' + errorMessage);
      });
  }

  async inAdmin() {
    var currentUser = await this.afAuth.currentUser;

    if (currentUser == null) {
      return false;
    }

    if ((await this.afs.collection('admin').doc(currentUser.uid).get().toPromise()).exists) {
      return true;
    } else {
      return false
    }
  }

  public async isAuthenticated(): Promise<boolean> {
    var currentUser = await this.afAuth.currentUser;
    if (currentUser != null) {
      return true;
    } else {
      return false;
    }
  }

  async signOut() {
    var currentUser = await this.afAuth.currentUser;
    await this.afAuth.signOut();
    this.router.navigate(['']);
  }

  async isLoggedIn() {

  await  this.afAuth.authState.subscribe(res => {
      if (res && res.uid) {    
        return true;
      }  
 
    });
    
    return false;
  }

}