import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact.model';
import { AppUtilServiceService } from 'src/app/services/app-util.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  public contactForm: FormGroup;
  public fm: Contact;
  submitted = false;
  public rtn;
  public rtnError = '';

  formattedaddress = "";
  options = {
    componentRestrictions: {
      country: ["US"]
    }
  }

  public AddressChange(address: any) {
    //setting address from API to local variable 
    this.formattedaddress = address.formatted_address;
  }

  constructor(public appUtil: AppUtilServiceService,
    private afs: AngularFirestore,) { }

  ngOnInit(): void {
    this.contactForm = new FormGroup({

      name: new FormControl('', Validators.required),
      phone: new FormControl('', [Validators.required, Validators.minLength(10)]),
      email: new FormControl('', [Validators.required, Validators.email]),
      subject: new FormControl('', Validators.required),
      address: new FormControl(''),
      message: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(300)]),
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.contactForm.controls; }


  async onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.contactForm.invalid) {
      return;
    }

    this.fm = this.contactForm.value;
    this.fm.createAt = firebase.default.firestore.FieldValue.serverTimestamp();
    this.fm.statusFlag = 'NEW';
    this.fm.propertyaddress = this.formattedaddress;

    return await this.afs.collection("contact-leads").doc()
      .set(this.fm)
      .then((res) => {
        console.log("Document successfully written!");
      })
      .catch((error) => {
        console.error("Error writing document: ", error);
        this.rtnError = error;
      }).finally(() => {
        this.rtn = 'Thank you, We will get back within 24 hours.';
        this.submitted = false;
        this.contactForm.reset();
      
      });

  }
}