import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireMessaging, AngularFireMessagingModule } from '@angular/fire/messaging';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { FaqComponent } from './home/faq/faq.component';
import { AboutUsComponent } from './home/about-us/about-us.component';
import { ContactUsComponent } from './home/contact-us/contact-us.component';
import { PrivacyComponent } from './home/privacy/privacy.component';
import { SellYourHomeComponent } from './sell-your-home/sell-your-home.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { HomeLeadsComponent } from './home-leads/home-leads.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OfferFormComponent } from './shared/offer-form/offer-form.component';
import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
//import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { MessagingService } from './services/messaging.service';
import { AsyncPipe } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FaqComponent,
    AboutUsComponent,
    ContactUsComponent,
    PrivacyComponent,
    SellYourHomeComponent,
    NotfoundComponent,
    HomeLeadsComponent,
    HeaderComponent,
    FooterComponent,
    OfferFormComponent,
    LoginComponent,
    AdminComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule, // firestore
    AngularFireAuthModule, // auth
    AngularFireStorageModule, // storage
    AngularFireMessagingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    GooglePlaceModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule

  ],
  providers: [AngularFireMessaging, MessagingService, AsyncPipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
