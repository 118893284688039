<div class="container">
    <div class="row">
        <div class="col-sm-10">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                <!-- Position Column -->
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef> Name </th>
                    <td mat-cell *matCellDef="let element"> {{element.name | titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> Email </th>
                    <td mat-cell *matCellDef="let element"> {{element.email | lowercase}} </td>
                </ng-container>

                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> Phone </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>

                <ng-container matColumnDef="address">
                    <th mat-header-cell *matHeaderCellDef> Address</th>
                    <td mat-cell *matCellDef="let element"> {{element.address| titlecase}} </td>
                </ng-container>

                <ng-container matColumnDef="createAt">
                    <th mat-header-cell *matHeaderCellDef> CreateAt </th>
                    <td mat-cell *matCellDef="let element"> {{element.createAt.seconds * 1000 | date:'MM/dd/yyyy hh:mm
                        a'}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="statusFlag">
                    <th mat-header-cell *matHeaderCellDef> Status Flag </th>
                    <td mat-cell *matCellDef="let element"> {{element.statusFlag}} </td>
                </ng-container>



                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>

        <div class="col-sm-1">
            <input type="button" class="btn btn-link" value="Request Permission" (click)="requestPermission()">
            <input type="button" class="btn btn-link" value="Send Massage" (click)="sendMsg()">
        </div>
    </div>
</div>