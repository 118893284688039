<footer id="footer">
    <div class="container">
        <div class="footer-ribbon">
            <span>Get in Touch</span>
        </div>
        <div class="row py-5 my-4">
            <div class="col-md-9 mb-4 mb-lg-0">
                <h5 class="text-3 mb-3">ABOUT US</h5>
                <p class="mt-2 mb-2">
                    {{appUtil.Company.Name}} is a residential real estate investment company headquartered in
                    National Harbor, MD.
                    By using House Buyers, home owners no longer have to pay real estate commissions, deal with real
                    estate agents or fix-up their properties.
                </p>
                <p>
                    <a routerLink="about-us" class="btn-flat btn-xs text-color-light"><strong class="text-2">VIEW
                            MORE</strong>
                        <em class="fas fa-angle-right p-relative top-1 pl-2"></em></a>
                </p>


                <div class="row pt-3" hidden>
                    <div class="col-6 col-lg-3 mb-4 mb-lg-0">
                        <h5 class="text-3 mb-3">BLOG</h5>
                        <ul class="list list-icons list-icons-sm mb-0">
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="blog-full-width.html">Blog Full Width</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="blog-large-image.html">Blog Large Image</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="blog-medium-image.html">Blog Medium Image</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="blog-post.html">Single Post</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-lg-3 mb-4 mb-lg-0">
                        <h5 class="text-3 mb-3">PAGES</h5>
                        <ul class="list list-icons list-icons-sm mb-0">
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-full-width.html">Full width</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-left-sidebar.html">Left sidebar</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-right-sidebar.html">Right sidebar</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-custom-header.html">Custom Header</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-lg-3 mb-4 mb-lg-0">
                        <h5 class="text-3 mb-3">PORTFOLIO</h5>
                        <ul class="list list-icons list-icons-sm mb-0">
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="portfolio-2-columns.html">2 Columns</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="portfolio-3-columns.html">3 Columns</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="portfolio-4-columns.html">4 Columns</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="portfolio-single-wide-slider.html">Single Project</a></li>
                        </ul>
                    </div>
                    <div class="col-6 col-lg-3 mb-4 mb-lg-0">
                        <h5 class="text-3 mb-3">EXTRA</h5>
                        <ul class="list list-icons list-icons-sm mb-0">
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-team.html">Team</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    href="page-services.html">Services</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    routerLink="/Privacy">Privacy Policy</a></li>
                            <li><em class="fas fa-angle-right top-8"></em> <a class="link-hover-style-1"
                                    routerLink="/">FAQ</a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-md-3 mb-4 mb-lg-0">
                <h5 class="text-3 mb-3 pb-1">CONTACT US</h5>


                <ul class="list list-icons list-icons-lg">
                    <li class="text-8 text-color-light font-weight-bold">
                        <a href='tel:{{appUtil.Company.Phone}}'>
                            <strong>{{appUtil.Company.Phone}}</strong>
                        </a>
                    </li>

                    <li class="mb-1"><em class="far fa-dot-circle text-color-primary"></em>
                        <address class="m-0">{{appUtil.Company.Address1}} <br />
                            {{appUtil.Company.Address2}}</address>
                    </li>
                    <li class="mb-1"><em class="far fa-envelope text-color-primary"></em>
                        <p class="m-0"><a href="mailto:{{ appUtil.Company.Email}}">{{ appUtil.Company.Email}}</a>
                        </p>
                    </li>
                </ul>
                <ul class="footer-social-icons social-icons mt-4">
                    <li class="social-icons-facebook"><a href="http://www.facebook.com/" target="_blank"
                            title="Facebook"><em class="fab fa-facebook-f"></em></a></li>
                    <li class="social-icons-twitter"><a href="http://www.twitter.com/" target="_blank"
                            title="Twitter"><em class="fab fa-twitter"></em></a></li>
                    <li class="social-icons-linkedin"><a href="http://www.linkedin.com/" target="_blank"
                            title="Linkedin"><em class="fab fa-linkedin-in"></em></a></li>
                </ul>
            </div>
        </div>

    </div>
    <div class="footer-copyright">
        <div class="container-fluid py-2">

            <div class="row py-4">
                <div
                    class="col-lg-2 d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0">
                    <a routerLink="/" class="logo pr-0 pr-lg-3">
                        <img alt="Home Buyers Biz" src="{{appUtil.HomeBuyersBizLogo()}}" class="opacity-5" height="50">
                    </a>
                </div>
                <div
                    class="col-lg-4 d-flex align-items-center justify-content-center justify-content-lg-start mb-0 mb-lg-0">
                    <p>{{appUtil.Company.Name}} © Copyright {{copyRightYr | date:'YYYY'}}. All Rights Reserved. Version
                        {{appUtil.WebsiteVersion() }}</p>
                </div>
                <div class="col-lg-4 d-flex  justify-content-lg-end">
                    <nav id="sub-menu">

                        <ul>

                            <li><em class="fas fa-angle-right"></em><a routerLink="faq"
                                    class="ml-1 text-decoration-none"> FAQ's</a></li>
                            <li><em class="fas fa-angle-right"></em><a routerLink="privacy" class="
                                    ml-1 text-decoration-none"> Privacy</a></li>
                            <li><em class="fas fa-angle-right"></em><a routerLink="contact-us"
                                    class="ml-1 text-decoration-none"> Contact Us</a></li>

                            <li *ngIf="isLoggedIn==false">
                                <em class="fas fa-angle-right"></em>
                                <a routerLink="login" class="ml-1 text-decoration-none"> Login</a>
                            </li>

                        </ul>
                    </nav>
                </div>
                <div class="col-lg-2 d-flex">
                    <nav id="sub-menu" *ngIf="isLoggedIn">
                        <ul>
                            <li><em class="fas fa-angle-right"></em>
                                <a routerLink="admin" class="ml-1 text-decoration-none"> Admin</a>
                            </li>
                            <li>
                                <em class="fas fa-angle-right"></em>
                                <a (click)="logout()" class="ml-1 text-decoration-none"> Logout</a>
                            </li>
                        </ul>
                    </nav>
        
                </div>
            </div>
        </div>
    </div>
</footer>