<div class="container py-4">

    <div class="row">
        <div class="col">

            <h2 class="font-weight-normal text-7 mb-2">Frequently Asked <strong
                    class="font-weight-extra-bold">Questions</strong></h2>
            <p class="lead" hidden></p>

            <hr class="solid my-5">

            <div class="toggle toggle-primary" data-plugin-toggle>
                <section class="toggle active">
                    <a class="toggle-title">
                        How do you determine the price to offer on my house?

                    </a>
                    <div class="toggle-content">
                        <p>
                            A: Great question, and we’re an open book: Our process is very straightforward. We look at
                            the location of the property, what repairs are needed, the current condition of the
                            property, and values of comparable houses sold in the area recently.

                        </p>
                        <p>
                            As you know, house values have taken a huge hit in the last 5 years and most areas still
                            haven’t seen prices come back up. We take many pieces of information into consideration… and
                            come up with a fair price that works for us and works for you too.
                        </p>
                    </div>
                </section>

                <section class="toggle">
                    <a class="toggle-title">
                        Are there any fees or commissions to work with you?
                    </a>
                    <div class="toggle-content">
                        <p>
                            This is what makes us stand out from the traditional method of selling your house:
                            <br /> There are NO fees or commissions when you sell your house us. We’ll make you an
                            offer, and if it’s a fit then we’ll buy your house (and we’ll often pay for the closing
                            costs too!). <strong>No hassle. No fees.</strong>
                            <br />We make our money after we pay for repairs on the house (if any) and sell it for a
                            profit (we’re taking all of the risk here on whether we can sell it for a profit or not,
                            once we buy the house from you… the responsibility is ours and you walk away without the
                            burden of the property and it’s payments… and often with cash in your hand).
                        </p>
                    </div>
                </section>

                <section class="toggle">
                    <a class="toggle-title">
                        How are you different from a real estate agent?
                    </a>
                    <div class="toggle-content">
                        <p>
                            Real estate agents list properties and hope that someone will buy them. The agent shows the
                            properties to prospective buyers if there are any (the average time to sell a property in
                            many markets right now is 6-12 months) and then take a percentage of the sale price if they
                            find a buyer.
                            <br />Oftentimes, the agent’s commission is 3-6% of the sale price of your house (so if it’s
                            a $100,000 house, you’ll pay between $3,000 – $6,000 in commissions to an agent). Agents
                            provide a great service for those that can wait 6-12 months to sell and who don’t mind
                            giving up some of that sale price to pay for the commissions. But that’s where we’re
                            different: We’re not agents, we’re home buyers.
                            <br />Our company actually buys houses. We don’t list houses. Since we’re actually the one
                            buying the house from you, and we pay with all cash… we can make a decision to buy your
                            house within a couple days (sometimes the same day). Again, we make our living by taking the
                            risk to buy the house with our own cash, repair the house, and market it ourselves to find a
                            buyer (which is the hard part in this market).
                        </p>
                    </div>
                </section>

                <section class="toggle">
                    <a class="toggle-title">
                        Is there any obligation when I submit my info?
                    </a>
                    <div class="toggle-content">
                        <p>
                            There is absolutely zero obligation for you. Once you tell us a bit about your property,
                            we’ll take a look at things, maybe set up a call with you to find out a bit more, and make
                            you an all-cash offer that’s fair for you and fair for us. From there, it’s 100% your
                            decision on whether or not you’d like to sell your house to us… and we won’t hassle you,
                            won’t harass you… it’s 100% your decision and we’ll let you decide what’s right for you.
                        </p>
                    </div>
                </section>




                <section class="toggle">
                    <a class="toggle-title">
                        Will you be listing my house on the MLS or actually buying it?
                    </a>
                    <div class="toggle-content">
                        <p>
                            Great question. We’re not agents, and we don’t list houses. We are professional home buyers:
                            <strong> We buy houses</strong> that meet our purchasing criteria. From there we may repair
                            the house and resell it to another home owner or keep it as a rental ourselves.
                        </p>
                    </div>
                </section>
            </div>

        </div>

    </div>



</div>