<div>
    <section class="section section-height-3 bg-color-grey-scale-0 m-0 border-0">
        <div class="container py-2 justify-content-md-center">

            <div class="row justify-content-center">
                <div class="col-10">
                    <div class="featured-box featured-box-primary text-left mt-0">
                        <div class="box-content">
                            <h4 class="color-primary font-weight-semibold text-4 text-uppercase mb-3 ">Sell your home
                                fast</h4>

                            <form [formGroup]="sellForm" (ngSubmit)="onSubmit()">

                                <div class="form-row">
                                    <div class="form-group col">
                                        <label for="propertyAddress"
                                            class="required font-weight-bold text-dark text-2">Property
                                            Address</label>



                                        <input ngx-google-places-autocomplete [options]='options'
                                            formControlName="address"
                                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                            (onAddressChange)="AddressChange($event)" maxlength="200"
                                            placeholder="Enter you property address..." class="form-control" required />

                                        <div *ngIf="f.address.invalid && (f.address.dirty || f.address.touched)"
                                            class="errortext">
                                            <div *ngIf="f.address.errors.required">Property Address is required</div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-3">
                                        <label for="PropertyType"
                                            class="required font-weight-bold text-dark text-2">Property
                                            Type</label>
                                        <select name="PropertyType" class="form-control" required
                                            formControlName="propertyType">
                                            <option value=""></option>
                                            <option *ngFor="let option of GetPropertyTypes" value="{{option.propType}}">
                                                {{option.propTypeDesc}}</option>
                                        </select>
                                        <div *ngIf="f.propertyType.invalid && (f.propertyType.dirty || f.propertyType.touched)"
                                            class="errortext">
                                            <div *ngIf="f.propertyType.errors.required">Property Type required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-lg-4">
                                        <label for="SellingReason"
                                            class="required font-weight-bold text-dark text-2">Reason For
                                            Wanting To Sell?</label>
                                        <select name="SellingReason" class="form-control" required
                                            [ngClass]="{'is-invalid': submitted && f.sellingReason.errors}"
                                            formControlName="sellingReason">
                                            <option value=""></option>
                                            <option *ngFor="let rs of GetSellingReasons" value="{{rs.reason_desc}}">
                                                {{rs.reason_desc}}</option>
                                        </select>
                                        <div *ngIf="f.sellingReason.invalid && (f.sellingReason.dirty || f.sellingReason.touched)"
                                            class="errortext">
                                            <div *ngIf="f.sellingReason.errors.required">Selling reasong required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="sellingTimeFrame"
                                            class="required font-weight-bold text-dark text-2">How Soon
                                            Would You Like To Sell?</label>
                                        <select name="sellingTimeFrame" class="form-control"
                                            [ngClass]="{'is-invalid': submitted && f.sellingTimeFrame.errors}"
                                            formControlName="sellingTimeFrame" required>
                                            <option value=""></option>
                                            <option *ngFor="let option of GetHomeSellTime" value="{{option.sellDays}}">
                                                {{option.daysDesc}}</option>
                                        </select>
                                        <div *ngIf="f.sellingTimeFrame.invalid && (f.sellingTimeFrame.dirty || f.sellingTimeFrame.touched)"
                                            class="errortext">
                                            <div *ngIf="f.sellingTimeFrame.errors.required">selection required</div>
                                        </div>
                                    </div>
                                    <div class="form-group col-lg-4">
                                        <label for="SellingReason"
                                            class="required font-weight-bold text-dark text-2">Listed With
                                            An Agent?</label>
                                        <select name="listedWithAgent" class="form-control"
                                            formControlName="listedWithAgent" required
                                            [ngClass]="{'is-invalid': submitted && f.listedWithAgent.errors}">
                                            <option value=""></option>
                                            <option value="Y">Yes</option>
                                            <option value="N">No</option>
                                        </select>
                                        <div *ngIf="f.listedWithAgent.invalid && (f.listedWithAgent.dirty || f.listedWithAgent.touched)"
                                            class="errortext">
                                            <div *ngIf="f.listedWithAgent.errors.required">selection required</div>
                                        </div>
                                    </div>

                                </div>
                                <div class="form-row">
                                    <div class="form-group col">
                                        <label for="propertyAddress"
                                            class="required font-weight-bold text-dark text-2">Full Name</label>
                                        <input type="text" maxlength="100" class="form-control" formControlName="name"
                                            placeholder="Enter you full name..."
                                            [ngClass]="{'is-invalid': submitted && f.listedWithAgent.errors}" required>
                                        <div *ngIf="f.name.invalid && (f.name.dirty || f.name.touched)"
                                            class="errortext">
                                            <div *ngIf="f.name.errors.required">Full Name is required</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col-lg-6">
                                        <label class="required font-weight-bold text-dark text-2">Phone</label>
                                        <input type="number" placeholder="(123) 456-7890" class="form-control"
                                            formControlName="phone" minlength="10" max="10" required
                                            [ngClass]="{ 'is-invalid': submitted && f.phone.errors }">

                                        <div *ngIf="f.phone.invalid && (f.phone.dirty || f.phone.touched)"
                                            class="errortext">
                                            <div *ngIf="f.phone.errors.required">
                                                Phone number is required.
                                            </div>
                                            <div *ngIf="f.phone.errors.minlength">
                                                Phone must be 10 digit long, we need another
                                                {{f.phone.errors.minlength.requiredLength -
                                                f.phone.errors.minlength.actualLength}}
                                                digits.
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-6">
                                        <label class="required font-weight-bold text-dark text-2">Email Address</label>
                                        <input formControlName="email" type="text" maxlength="100" class="form-control"
                                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

                                        <div *ngIf="f.email.invalid && (f.email.dirty || f.email.touched)"
                                            class="errortext">
                                            <div *ngIf="f.email.errors.required">Email is required</div>
                                            <div *ngIf="f.email.errors.email">Enter valid email address</div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col">
                                        <label class="font-weight-bold text-dark text-2">Message</label>
                                        <textarea minlength="10" maxlength="500" rows="4" class="form-control"
                                            formControlName="message" laceholder="Notes...">
                                        </textarea>

                                    </div>
                                </div>

                                <div class="form-row">
                                    <div class="form-group col text-right">
                                        <div class="d-grid gap-2 col-6 pull-right">
                                            <button
                                                class="btn btn-primary bg-color-hover-primary btn-block px-4 py-md-2"
                                                type="submit">
                                                <span *ngIf="submitted" class="spinner-grow spinner-grow-sm"
                                                    role="status" aria-hidden="true"></span>
                                                Submit
                                            </button>
                                        </div>
                                        <button class="btn btn-secondary px-4 py-md-2" type="reset"
                                            (click)="onReset()">Cancel</button>
                                    </div>
                                </div>

                                <div class="alert alert-success" role="alert" *ngIf="msg">
                                    <strong>Success!</strong> {{msg}}
                                </div>

                                <div class="alert alert-danger" role="alert" *ngIf="msgError">
                                    <strong>Error!</strong> {{msgError }}
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>