<div class="slider-with-overlay">
    <div class="slider-container rev_slider_wrapper" style="height: 0px;">
        <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider
            data-plugin-options="{'delay': 9000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': true }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">

            <ul>
                <li class="slide-overlay slide-overlay-level-7" data-transition="fade">

                    <div class="tp-caption tp-resizeme rs-parallaxlevel-7"
                        data-frames='[{"delay":2500,"speed":1500,"frame":"0","from":"opacity:0;x:-50%;y:-50%;","to":"opacity:1;x:0;y:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-type="image" data-x="-500" data-y="-700" data-width="['auto']" data-height="['auto']"
                        data-basealign="slide">
                        <img src="assets/img/slides/slide-parallax-porto-symbol.png" alt="">
                    </div>


                    <div class="tp-caption" data-x="['left','left','center','center']"
                        data-hoffset="['145','145','-150','-240']" data-y="center"
                        data-voffset="['-50','-50','-50','-75']" data-start="1000"
                        data-transform_in="x:[-300%];opacity:0;s:500;" data-transform_idle="opacity:0.2;s:500;"><img
                            src="assets/img/slides/slide-title-border.png" alt=""></div>

                    <div class="tp-caption text-color-light font-weight-normal"
                        data-x="['left','left','center','center']" data-hoffset="['200','200','0','0']" data-y="center"
                        data-voffset="['-50','-50','-50','-75']" data-start="700" data-fontsize="['22','22','22','40']"
                        data-lineheight="['25','25','25','45']" data-transform_in="y:[-50%];opacity:0;s:500;">We Buy
                        Houses Ca$h</div>

                    <div class="tp-caption d-none d-md-block"
                        data-frames='[{"delay":3800,"speed":500,"frame":"0","from":"opacity:0;x:10%;","to":"opacity:1;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-x="['left','left','center','center']" data-hoffset="['365','365','80','135']"
                        data-y="center" data-voffset="['-33','-33','-33','-95']"><img
                            src="assets/img/slides/slide-blue-line.png" alt=""></div>

                    <div class="tp-caption" data-x="['left','left','center','center']"
                        data-hoffset="['440','440','150','240']" data-y="center"
                        data-voffset="['-50','-50','-50','-75']" data-start="1000"
                        data-transform_in="x:[300%];opacity:0;s:500;" data-transform_idle="opacity:0.2;s:500;"><img
                            src="assets/img/slides/slide-title-border.png" alt=""></div>


                    <h1 class="tp-caption font-weight-extra-bold text-color-light negative-ls-2"
                        data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:1.5;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                        data-x="['left','left','center','center']" data-hoffset="['152','152','0','0']" data-y="center"
                        data-fontsize="['50','50','50','90']" data-lineheight="['55','55','55','95']"
                        data-letterspacing="-1">
                        Nationwide
                    </h1>

                    <div class="tp-caption font-weight-bold  word-rotator slide appear-animation"
                        data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":3000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                        data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']" data-y="center"
                        data-voffset="['65','40','40','80']" data-fontsize="['18','18','18','50']"
                        data-lineheight="['20','20','20','55']" style="color: #b5b5b5;"
                        data-appear-animation="fadeInUpShorter">
                        <span class="word-rotator-words">
                            <strong>You Pay Zero Closing Costs</strong>
                            <strong>No Commissions No Fees</strong>
                            <strong>Sell Your House As-Is For All CASH $$$</strong>
                            <strong>Leave the tenants to us</strong>
                            <strong>No realtors needed</strong>
                            <strong>No Obligations - 100% FREE!</strong>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <div class="slider-contact-form">
        <div class="container">
            <div class="row justify-content-end">
                <div class="col-lg-6">
                    <div class="slider-contact-form-wrapper bg-color-primary bg-hover-quaternary  rounded p-5 appear-animation"
                        data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="2000">
                        <div class="row">
                            <div class="col text-center">
                                <h2 class="font-weight-light text-color-light text-6 mb-2">Are you ready to sell your
                                    <strong>house?</strong>
                                </h2>
                                <p class="text-color-light opacity-7 font-weight-light mb-4 px-5">We are investors that
                                    buy houses "as is" and can close whenever you want.</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="p-0">
                                    <app-offer-form></app-offer-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="false">
    <div class="slider-with-overlay">
        <div class="slider-container rev_slider_wrapper" style="height: 670px;">
            <div id="revolutionSlider" class="slider rev_slider" data-version="5.4.8" data-plugin-revolution-slider
                data-plugin-options="{'delay': 9000, 'gridwidth': 1170, 'gridheight': 670, 'disableProgressBar': 'on', 'responsiveLevels': [4096,1200,992,500], 'parallax': { 'type': 'scroll', 'origo': 'enterpoint', 'speed': 1000, 'levels': [2,3,4,5,6,7,8,9,12,50], 'disable_onmobile': 'on' }, 'navigation' : {'arrows': { 'enable': true }, 'bullets': {'enable': true, 'style': 'bullets-style-1', 'h_align': 'center', 'v_align': 'bottom', 'space': 7, 'v_offset': 70, 'h_offset': 0}}}">
                <ul>
                    <li class="slide-overlay slide-overlay-level-7" data-transition="fade">

                        <div class="tp-caption tp-resizeme rs-parallaxlevel-7"
                            data-frames='[{"delay":2500,"speed":1500,"frame":"0","from":"opacity:0;x:-50%;y:-50%;","to":"opacity:1;x:0;y:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                            data-type="image" data-x="-500" data-y="-700" data-width="['auto']" data-height="['auto']"
                            data-basealign="slide">
                            <img src="~/img/slides/slide-parallax-porto-symbol.png" alt="">
                        </div>


                        <div class="tp-caption" data-x="['left','left','center','center']"
                            data-hoffset="['145','145','-150','-240']" data-y="center"
                            data-voffset="['-50','-50','-50','-75']" data-start="1000"
                            data-transform_in="x:[-300%];opacity:0;s:500;" data-transform_idle="opacity:0.2;s:500;"><img
                                src="~/img/slides/slide-title-border.png" alt=""></div>

                        <div class="tp-caption text-color-light font-weight-normal"
                            data-x="['left','left','center','center']" data-hoffset="['200','200','0','0']"
                            data-y="center" data-voffset="['-50','-50','-50','-75']" data-start="700"
                            data-fontsize="['22','22','22','40']" data-lineheight="['25','25','25','45']"
                            data-transform_in="y:[-50%];opacity:0;s:500;">We Buy Houses Ca$h</div>

                        <div class="tp-caption d-none d-md-block"
                            data-frames='[{"delay":3800,"speed":500,"frame":"0","from":"opacity:0;x:10%;","to":"opacity:1;x:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['365','365','80','135']"
                            data-y="center" data-voffset="['-33','-33','-33','-95']"><img
                                src="~/img/slides/slide-blue-line.png" alt=""></div>
                        <div class="tp-caption" data-x="['left','left','center','center']"
                            data-hoffset="['440','440','150','240']" data-y="center"
                            data-voffset="['-50','-50','-50','-75']" data-start="1000"
                            data-transform_in="x:[300%];opacity:0;s:500;" data-transform_idle="opacity:0.2;s:500;"><img
                                src="~/img/slides/slide-title-border.png" alt=""></div>


                        <h1 class="tp-caption font-weight-extra-bold text-color-light negative-ls-2"
                            data-frames='[{"delay":1000,"speed":2000,"frame":"0","from":"sX:1.5;opacity:0;fb:20px;","to":"o:1;fb:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['152','152','0','0']"
                            data-y="center" data-fontsize="['50','50','50','90']"
                            data-lineheight="['55','55','55','95']" data-letterspacing="-1">
                            Nationwide
                        </h1>
                    </li>

                    <li class="slide-overlay slide-overlay-level-10" data-transition="fade">

                        <div class="tp-caption tp-resizeme rs-parallaxlevel-7"
                            data-frames='[{"delay":2500,"speed":1500,"frame":"0","from":"opacity:0;x:-50%;y:-50%;","to":"opacity:1;x:0;y:0;","ease":"Power3.easeInOut"},{"delay":"wait","speed":300,"frame":"999","to":"opacity:0;fb:0;","ease":"Power3.easeInOut"}]'
                            data-type="image" data-x="-500" data-y="-700" data-width="['auto']" data-height="['auto']"
                            data-basealign="slide">
                            <img src="~/img/slides/slide-parallax-porto-symbol.png" alt="">
                        </div>


                        <div class="tp-caption font-weight-bolder"
                            data-frames='[{"from":"opacity:0;","speed":300,"to":"o:1;","delay":2000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['40','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">You Pay Zero Closing Costs
                        </div>

                        <div class="tp-caption font-weight-bold"
                            data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":3000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['65','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">No Commissions No Fees</div>



                        <div class="tp-caption tp-resizeme rs-parallaxlevel-7 font-weight-bold"
                            data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":4000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['90','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">
                            Sell Your House As-Is For All CASH <strong>AS IS</strong>
                        </div>

                        <div class="tp-caption font-weight-bold"
                            data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":5000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['115','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">
                            Leave the tenants to us
                        </div>


                        <div class="tp-caption font-weight-bold"
                            data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":6000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['140','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">
                            No realtors needed
                        </div>
                        <div class="tp-caption font-weight-bold"
                            data-frames='[{"from":"opacity:0;","speed":270,"to":"o:1;","delay":7000,"split":"chars","splitdelay":0.05,"ease":"Power2.easeInOut"},{"delay":"wait","speed":1000,"to":"y:[100%];","mask":"x:inherit;y:inherit;s:inherit;e:inherit;","ease":"Power2.easeInOut"}]'
                            data-x="['left','left','center','center']" data-hoffset="['166','166','0','0']"
                            data-y="center" data-voffset="['165','40','40','80']" data-fontsize="['18','18','18','50']"
                            data-lineheight="['20','20','20','55']" style="color: #b5b5b5;">
                            No Obligations - 100% FREE!
                        </div>



                    </li>
                </ul>
            </div>
        </div>
        <div class="slider-contact-form">
            <div class="container">
                <div class="row justify-content-end">
                    <div class="col-lg-6">
                        <div class="slider-contact-form-wrapper bg-color-primary bg-hover-quaternary  rounded p-5 appear-animation"
                            data-appear-animation="fadeInLeftShorter" data-appear-animation-delay="2000">
                            <div class="row">
                                <div class="col text-center">
                                    <h2 class="font-weight-light text-color-light text-6 mb-2">Are you ready to sell
                                        your <strong>house?</strong></h2>
                                    <p class="text-color-light opacity-7 font-weight-light mb-4 px-5">We are investors
                                        that buy houses "as is" and can close whenever you want.</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    Html.RenderPartialAsync("_GetOfferForm")
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="home-intro" id="home-intro2">
    <div class="row align-items-center">
        <div class="col text-center">
            <p class="mb-0">
                We Will Pay Cash For Your House <span
                    class="highlighted-word highlighted-word-animation-1 text-color-primary font-weight-semibold text-5">No
                    Matter</span> the Condition is...
                <span>Check out our options and features included.</span>
            </p>
        </div>
    </div>

</div>

<div class="container pb-1">
    <div class="row pt-4">
        <div class="col">
            <div class="overflow-hidden mb-3">
                <h2 class="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">
                    We Are Here To Help You
                </h2>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-lg-10">
            <div class="overflow-hidden">
                <p class="lead mb-0 appear-animation" data-appear-animation="maskUp" data-appear-animation-delay="200">
                    We GUARANTEE our offer and can have a check in your hand as quickly as <span
                        class="alternative-font"> 7 </span> days
                </p>
            </div>
        </div>
        <div class="col-lg-2 appear-animation" data-appear-animation="fadeIn" data-appear-animation-delay="500">
            <a routerLink="/sell-your-home" class="btn btn-modern btn-dark mt-1">Get a Quote!</a>
        </div>
    </div>

    <div class="row">
        <div class="col py-3">
            <hr class="solid mt-5 mb-2">
        </div>
    </div>

</div>

<div class="container py-2">
    <div class="row">
        <div class="featured-boxes featured-boxes-style-8">
            <div class="row pt-4">
                <div class="col">
                    <div class="overflow-hidden mb-3">
                        <h2 class="font-weight-bold text-8 mb-0 appear-animation" data-appear-animation="maskUp">
                            How The Process Works
                        </h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4">
                    <div class="featured-box featured-box-primary featured-box-effect-1">
                        <div class="box-content p-5">
                            <h4 class="font-weight-normal text-5 text-dark">Step 1 <strong
                                    class="font-weight-extra-bold">Contact Us</strong></h4>
                            <p class="mb-0 text-left">
                                Tell us about your property – Simply Call Us or Fill Out the short form
                            </p>
                            <span class="text-primary font-weight-bold alternative-font-2">Quick, Easy &
                                Free!                               
                            </span>
                            <a routerLink="/sell-your-home" class="text-info text-decoration-none"> Apply </a>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="featured-box featured-box-primary featured-box-effect-1">
                        <div class="box-content p-5">

                            <h4 class="font-weight-normal text-5 text-dark">Step 2 <strong
                                    class="font-weight-extra-bold">Get an Offer</strong></h4>
                            <p class="mb-0 text-left">
                                We’ll present you w/ a fair written,
                                <br><br> <span class="text-primary font-weight-bold alternative-font-2"> no-obligation
                                    offer</span>
                            </p>

                        </div>
                    </div>
                </div>

                <div class="col-lg-4">
                    <div class="featured-box featured-box-primary featured-box-effect-1">
                        <div class="box-content p-5">

                            <h4 class="font-weight-normal text-5 text-dark">Step 3 <strong
                                    class="font-weight-extra-bold">Get Paid</strong></h4>
                            <p class="mb-0  text-left">
                                We close at a reputable title company, cash in your hands in as little as 7 days.
                            </p>
                            <br>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>

<section class="section section-height-3 bg-color-grey-scale-0 m-0 border-0">
    <div class="container py-2">
        <div class="row justify-content-center">
            <div class="col text-center">
                <h3 class="appear-animation" data-appear-animation="fadeInUpShorter">We Will Pay Cash For Your
                    House<span class="alternative-font"><strong> No Matter the Situation</strong></span> </h3>
                <p class="line-height-9 text-4 opacity-9 appear-animation" data-appear-animation="fadeInUpShorter"
                    data-appear-animation-delay="200">
                    Sell your house without a realtor. We buy houses AS-IS. Condition and situation? No factor. We’ve
                    seen and done it all. No matter the reason, we have the experience and resources at hand to solve
                    your property problems with expertise and care.
                </p>
            </div>
        </div>

        <div class="row content-align-center">


            <div class="col-sm-2 col-md-4 ws-nowrap">
                <ul class="list list-icons">
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="0"><em
                            class="fas fa-home"></em>Avoiding Foreclosure</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="300"><em
                            class="fas fa-home"></em> <strong>Frustrating Tenants</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="600"><em
                            class="fas fa-home"></em>Delinquent Payments</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="900"><em
                            class="fas fa-home"></em><strong>Too Many Repairs</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1200"><em
                            class="fas fa-home"></em>Code Violations</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1500"><em
                            class="fas fa-home"></em>Divorce</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1800"><em
                            class="fas fa-home"></em>Medical Bills</li>
                </ul>
            </div>

            <div class="col-md-4 ws-nowrap">
                <ul class="list list-icons">
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="100"><em
                            class="fas fa-home"></em><strong>Upside-Down Mortgage</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="250"><em
                            class="fas fa-home"></em><strong>Eliminate Debt</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="500"><em
                            class="fas fa-home"></em>Retiring</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="750"><em
                            class="fas fa-home"></em>Bankruptcy</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1000"><em
                            class="fas fa-home"></em><strong>Out-Of-State</strong> Owner</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1250"><em
                            class="fas fa-home"></em>Behind On <strong>Property Taxes</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1500"><em
                            class="fas fa-home"></em>Family Loss</li>
                </ul>
            </div>

            <div class="col-md-4 ws-nowrap">
                <ul class="list list-icons">
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="200"><em
                            class="fas fa-home"></em><strong>Inherited</strong> A House</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="350"><em
                            class="fas fa-home"></em><strong>Relocating</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="700"><em
                            class="fas fa-home"></em>Owe <strong>Liens</strong></li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="500"><em
                            class="fas fa-home"></em>Water Or Fire Damage</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1000"><em
                            class="fas fa-home"></em>Settling Probate</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1250"><em
                            class="fas fa-home"></em>Downsizing</li>
                    <li class="appear-animation" data-appear-animation="fadeInUp" data-appear-animation-delay="1500"><em
                            class="fas fa-home"></em>Job Relocation</li>
                </ul>
            </div>
        </div>
    </div>
</section>


<section class="section   bg-color-primary m-0 border-0">
    <div class="container py-2">
        <div class="row justify-content-center">
            <div class="col text-center">
                <h4><a routerLink="/sell-your-home" class=" text-color-black text-decoration-none"> <strong>Apply for No
                            Obligation and Confidential Cash Offer. </strong></a> </h4>
                <h4><a routerLink="/sell-your-home" class=" text-color-black text-decoration-none">Our local serving
                        area but not limited to </a> </h4>
            </div>
        </div>

        <div class="row">
            <div *ngFor="let x of GetServingLocations" class="col-md-2 text-color-black text-decoration-none">
                {{x.city}}, {{x.state}} &nbsp;
            </div>
        </div>
    </div>
</section>